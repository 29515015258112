import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  HorizontalStack,
  Icon,
  KCol,
  KModal,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SiskonMaster_Screen } from "../siskonmaster/siskonmaster";
import { Company_List_ScreenBase } from "./company_list-base";

import { Company_Form_Screen } from "../company_form/company_form";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class Company_List_Screen extends Company_List_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <SiskonMaster_Screen
          form={this.props.form}
          initialValues={{ company_list_416063_value: undefined }}
          screenInputs={this.props.screenInputs}
          setMasterPageInitLoaded={this.setMasterPageInitLoaded}
          setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded}
          isMasterPageInitLoaded={this.state.isMasterPageInitLoaded}
          isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded}
          shouldTriggerPageInit={this.state.shouldTriggerPageInit}
          setShouldTriggerPageInit={this.setShouldTriggerPageInit}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="company_list_body"
              style={
                {
                  backgroundColor: "rgba(247, 247, 249, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  paddingTop: 8,
                  paddingRight: 8,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  maxHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  height: "calc(100vh - 60px - 60px - 0px - 0px)"
                } as any
              }
            >
              <KRow
                id="170487"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KCol
                  id="516058"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 10,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="331958"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="44993"
                      xs={15}
                      sm={15}
                      md={15}
                      lg={15}
                      xl={15}
                      xxl={15}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Label
                        id="763964"
                        value={ReactSystemFunctions.translate(this.ml, 763964, "value", this.defaultML)}
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "18px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      ></Label>
                    </KCol>
                  </KRow>

                  <KRow
                    id="491343"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="338418"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Button
                        id="814413"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.Company_ListComponent_814413_onClick();
                        }}
                        showCursorPointer
                        visibility={this.state.isCompcompany_list_814413AuthorizationVisible}
                        visibilityByAuthorization={this.state.isCompcompany_list_814413AuthorizationVisible}
                        label={ReactSystemFunctions.translate(this.ml, 814413, "label", this.defaultML)}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        icon="add"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            backgroundColor: "rgba(17, 144, 205, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Button>
                    </KCol>

                    <KCol
                      id="597510"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          alignItems: "flex-start",
                          textAlign: "-webkit-right",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Form.Item className="kFormItem" name="company_list_416063_value">
                        <TextInput
                          id="416063"
                          onChange={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.Company_ListComponent_416063_onChange();
                          }}
                          kuikaRef={this.company_list_416063_value_kuikaTextInputRef}
                          placeholder={ReactSystemFunctions.translate(this.ml, 416063, "placeholder", this.defaultML)}
                          allowClear={false}
                          bordered={true}
                          disabled={false}
                          type="text"
                          iconColor="rgba(0, 0, 0, 1)"
                          formatter="None"
                          isCharOnly={false}
                          autoComplete={true}
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(255, 255, 255, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              maxWidth: "200px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        ></TextInput>
                      </Form.Item>
                    </KCol>
                  </KRow>

                  <KRow
                    id="52153"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="96805"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="720564"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="769800"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingLeft: 10,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="399769"
                            kuikaRef={this.company_list_399769_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.GetCompanies}
                            size="middle"
                            bordered={true}
                            loading={false}
                            transformedOnMobileResolution={false}
                            showHeader={true}
                            fixedHeader={false}
                            editable={false}
                            tableWidthMode="fit"
                            globalSearch={false}
                            searchable={false}
                            sorter={false}
                            filtering={false}
                            pagination={false}
                            pageSize={10}
                            showSizeChanger={false}
                            columnChooser={false}
                            resizableColumns={false}
                            striped={false}
                            stripedColor="#F5F7FA"
                            insertRowActive={false}
                            insertRowPosition="top"
                            showNoDataFound={true}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              399769,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            multiSelect={false}
                            export={false}
                            editableAlwaysActive={false}
                            hideHeaderOnMobileResolution={false}
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="500856"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(155, 155, 155, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="489075"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(217, 217, 217, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="792509"
                                title={ReactSystemFunctions.translate(this.ml, 792509, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "40px",
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="893506"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderTopLeftRadius: 0,
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="860665"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.Company_ListComponent_860665_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="create"
                                    hoverText="Düzenle"
                                    style={
                                      {
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(17, 144, 205, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>

                                  <Icon
                                    id="505853"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.Company_ListComponent_505853_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="delete_forever"
                                    hoverText="Sil"
                                    style={
                                      {
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(250, 84, 28, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </HorizontalStack>
                              </KTableColumn>

                              <KTableColumn
                                id="684488"
                                title={ReactSystemFunctions.translate(this.ml, 684488, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="985524"
                                  value="[datafield:companyCode]"
                                  style={
                                    {
                                      borderTopLeftRadius: 0,
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="3491822"
                                title={ReactSystemFunctions.translate(this.ml, 3491822, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="3941056"
                                  value="[datafield:companyName]"
                                  style={
                                    {
                                      borderTopLeftRadius: 0,
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="482549"
                                title={ReactSystemFunctions.translate(this.ml, 482549, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="company_list_105806_value">
                                  <Checkbox
                                    id="105806"
                                    children={ReactSystemFunctions.translate(
                                      this.ml,
                                      105806,
                                      "children",
                                      this.defaultML
                                    )}
                                    disabled={false}
                                    value="[datafield:active]"
                                    style={
                                      {
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        color: "rgba(255, 255, 255, 1)",
                                        lineHeight: "22px"
                                      } as any
                                    }
                                  ></Checkbox>
                                </Form.Item>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KCol>
                      </KRow>

                      <KRow
                        id="143508"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="789073"
                          xs={18}
                          sm={18}
                          md={18}
                          lg={18}
                          xl={18}
                          xxl={18}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingLeft: 10,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KPagination
                            id="249452"
                            onChange={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.Company_ListComponent_249452_onChange();
                            }}
                            kuikaRef={this.company_list_249452_value_kuikaPaginationRef}
                            total={this.state.GetCompaniesCount?.at?.(0)?.totalRecords ?? undefined}
                            pageSize={20}
                            defaultCurrent={1}
                            current={1}
                            defaultPageSize={10}
                            disabled={false}
                            hideOnSinglePage={true}
                            showLessItems={false}
                            showQuickJumper={false}
                            showSizeChanger={true}
                            options={[{ value: "Item 1" }, { value: "Item 2" }]}
                            showFirstPageButton={false}
                            showLastPageButton={false}
                            showNumbers={true}
                            showText={false}
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0
                              } as any
                            }
                          ></KPagination>
                        </KCol>

                        <KCol
                          id="628514"
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 10,
                              paddingRight: 10,
                              paddingBottom: 10,
                              paddingLeft: 10,
                              alignItems: "flex-start",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="789496"
                            direction="horizontal"
                            size={5}
                            style={
                              {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="753114"
                              value={this.state.GetCompaniesCount?.at?.(0)?.totalRecords ?? undefined}
                              style={
                                {
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="500603"
                              value={ReactSystemFunctions.translate(this.ml, 500603, "value", this.defaultML)}
                              style={
                                {
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KModal
                id="912034"
                visible={this.state.NavVisible912034}
                onOk={() => {
                  this.setState({ NavVisible912034: false }, () => {
                    this.callCallbackFunction(912034);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible912034: false }, () => {
                    this.callCallbackFunction(912034);
                  });
                }}
                closable={true}
                width="800px"
                footer={null}
                height="vh"
                children={
                  this.state.NavVisible912034 && (
                    <Company_Form_Screen
                      onClose={() => {
                        this.setState({ NavVisible912034: false }, () => {
                          this.callCallbackFunction(912034);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                      navigator={this.props.navigator}
                      form={this.props.form}
                      context={this.props.context}
                    />
                  )
                }
              ></KModal>

              <KModal
                id="578795"
                visible={this.state.NavVisible578795}
                onOk={() => {
                  this.setState({ NavVisible578795: false }, () => {
                    this.callCallbackFunction(578795);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible578795: false }, () => {
                    this.callCallbackFunction(578795);
                  });
                }}
                closable={true}
                width="800px"
                footer={null}
                height="334px"
                children={
                  this.state.NavVisible578795 && (
                    <Company_Form_Screen
                      onClose={() => {
                        this.setState({ NavVisible578795: false }, () => {
                          this.callCallbackFunction(578795);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                      navigator={this.props.navigator}
                      form={this.props.form}
                      context={this.props.context}
                    />
                  )
                }
              ></KModal>
            </div>
          </Spin>
        </SiskonMaster_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Company_List_Screen)))))
);
export { tmp as Company_List_Screen };
//export default tmp;
//export { tmp as Company_List_Screen };
